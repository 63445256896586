export enum salaryTimePeriodsEnum {
  per_year = "per year",
  per_month = "per month",
  per_week = "per week",
  per_hour = "per hour",
}

export enum workingHoursEnum {
  full_time = "Full Time",
  part_time = "Part Time",
}

export const JOBS_PER_PAGE = 50;
